import React from "react"
import { useState, useEffect } from 'react';
import {withRouter} from 'react-router';

import AdminGridContent from "./AdminGridContent"

function AdminArticle(props){

    const [articles, setArticles] = useState(props.articles)

    useEffect(() => {
      setArticles(props.articles)
    }, [props]);

      if (articles.length !== 0) {
          return (
              <div>
              <AdminGridContent articles={articles}/>
              </div>
          )
      } else {
            return (
              <div>
              <p>The Article Does Not Exist!</p>
              </div>
            )}
}

export default withRouter(AdminArticle)
