import React, { Component } from "react";

export default class AccountDetailsUpdatePaymentEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      email: "",
      success: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async handleSubmitEmail (event) {
    event.preventDefault();
    const updateStripeCustomerResponse = await this.updateStripeCustomer(this.props.user)
    this.setState({success:true})
  }

  async updateStripeCustomer (user) {

    const firestoreResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/getFirestoreCustomer', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              userId: user.uid,
              email: this.state.email,
              })
      })

      const firestoreData = await firestoreResponse.json()
      var firestoreBody = JSON.parse(firestoreData.body);
      var customerId = firestoreBody.customerId;

    const stripeResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/updateStripeCustomer', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              customerId: customerId,
              })
      })

    const updateData = await stripeResponse.json()
    var updateBody = JSON.parse(updateData.body);
    var updateResponse = updateBody.response;
    return updateResponse

  }

  render() {

  if(this.state.success){

              return (
                
                <div>
                <section>
                <hr/>
                <div style={{textAlign:"center"}}>
                <h1>Account Credentials</h1>
                <p>Your payment email has been updated.</p>
                </div>
                <hr/>
                </section>
                </div>

              )

  } else {

              return (
                <div className="centicons" style={{textAlign:"center"}}>
                <section>
                  <form
                    autoComplete="off"
                    onSubmit={this.handleSubmitEmail}
                  >
                    <h1>
                      Update your Credentials
                    </h1>
                    <p>
                      Fill in the form below to update your payment email.
                    </p>
                    <div>
                      <input
                        placeholder="New Email"
                        name="email"
                        type="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        className="inputBox"
                      />
                    </div>
                    <div>
                      {this.state.error ? (
                        <p>{this.state.error}</p>
                      ) : null}
                      <button type="submit" className="btn extra auth">Update Email</button>
                    </div>
                  </form>
                </section>
                </div>
              );

    }

  }
}
