import React from "react"

function DateModalCalcVolume(){

    var d = new Date();
    var year = d.getFullYear();
    var monthx = d.getMonth();
    var day = d.getDate();
    var month = "";

    switch(monthx) {
        case 0:
            month = "Jan.".toUpperCase()
          break;
        case 1:
            month = "Feb.".toUpperCase()
          break;
        case 2:
            month = "Mar.".toUpperCase()
          break;
        case 3:
            month = "Apr.".toUpperCase()
          break;
        case 4:
            month = "May.".toUpperCase()
          break;
        case 5:
            month = "Jun.".toUpperCase()
          break;
        case 6:
            month = "Jul.".toUpperCase()
          break;
        case 7:
            month = "Aug.".toUpperCase()
          break;    
        case 8:
            month = "Sep.".toUpperCase()
          break;
        case 9:
            month = "Oct.".toUpperCase()
          break;
        case 10:
            month = "Nov.".toUpperCase()
          break;
        case 11:
            month = "Dec.".toUpperCase()
          break;

        default:
            month = "Jan.".toUpperCase()
      }

    if(day <= 7)  {

        return (

            <div>
            <p className="timingext">{1}-{7} {month} {year}</p>
            </div>
      
          )       
    } else if (day <= 14) {

    return (

      <div>
      <p className="timingext">{8}-{14} {month} {year}</p>
      </div>

    )

    } else if (day <= 21) {

    return (

      <div>
      <p className="timingext">{8}-{14} {month} {year}</p>
      </div>

    )

    } else if (month <= 7 && month % 2 == 0) {

    return (

      <div>
      <p className="timingext">{22}-{30} {month} {year}</p>
      </div>

    )

    } else if (month <= 7 && month % 2 == 1) {

    return (

      <div>
      <p className="timingext">{22}-{31} {month} {year}</p>
      </div>

    )

    } else if (month % 2 == 0) {

    return (

      <div>
      <p className="timingext">{22}-{31} {month} {year}</p>
      </div>

    )

    } else {

        return (

            <div>
            <p className="timingext">{22}-{30} {month} {year}</p>
            </div>
      
          )
    }




}

export default DateModalCalcVolume
