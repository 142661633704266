import React from "react"
import {Link} from "react-router-dom";
import {withRouter} from 'react-router';

import IconBar from "./IconBar"
import AdminIconModal from "./AdminIconModal"

function AdminGridContent(props){

      return (
        <div>
        <section>
            <article className="post">
            {props.articles.map(article => (
              <div>
              <Link to={article.location}><h1>{article.title}</h1></Link>
              <p>{article.content}</p>
              <div><img src={article.url}
                        style={{display: "block",
                                width: "100%",
                                height: "100%",
                                objectFit: "contain"}}
                         alt={article.alt}       
                    />
              <IconBar article={article}/>
              <AdminIconModal article={article}/>
              </div>
              </div>
            ))}
            </article>
        </section>
        </div>
      )
    }

export default withRouter(AdminGridContent)
