import React from "react"
import { useState, useEffect } from "react"

import UseWindowSize from "./UseWindowSize"

function ExtendedNav(props){

const size = UseWindowSize();

      const [clicked, setClicked] = useState(false)

      function handleClick(){
        if(clicked){
          setClicked(false)
        } else {
          setClicked(true)
        }
      }

      const [coverLink, setcoverLink] = useState("")
      const [aboutLink, setaboutLink] = useState("")
      const [teamLink, setteamLink] = useState("")
      const [homeLink, sethomeLink] = useState("")

      useEffect(() => {

          setcoverLink("/")
          setaboutLink("/about")
          setteamLink("https://www.kujeera.com/@theteam")
          sethomeLink("https://www.kujeera.com")

    }, []);

      return (

        /*
        <div>
        <section id="myDIV" style={{display:"none"}}>
        <div className="icons">
        <div className="lefticons">
        <a href={subscribeLink}>
        <p className="timingext" style={{fontWeight:"normal", paddingRight:"2em"}}>SUBSCRIBE</p>
        </a>
        <a href={aboutLink}>
        <p className="timingext" style={{fontWeight:"normal", paddingRight:"2em"}}>ABOUT</p>
        </a>
        <a href={newsletterLink}>
        <p className="timingext" style={{fontWeight:"normal"}}>SHOWCASE</p>
        </a>
        </div>
        <a href={careersLink}>
        <p className="timingext" style={{fontWeight:"normal"}}>INTERNSHIPS</p>
        </a>
        </div>
        <hr/>
        </section>
        </div>

        */


       <div>
       <section id="myDIV" style={{display:"none"}}>
       <ul>
       <a href={coverLink}>
       <li><span style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>cover</em></p></span></li>
       </a>
       <li><span style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}>|</p></span></li>
       {/*<a href={homeLink}>
       <li><span style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#say</em></p></span></li>
       </a>*/}
       <a href={aboutLink}>
       <li><span style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#populist</em></p></span></li>
       </a>
       {/*
       <a href={teamLink}>
       <li><span style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#name</em></p></span></li>
       </a>
       */}
       </ul>
       </section>
       </div>

      )
    }

export default ExtendedNav
