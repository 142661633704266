import React from 'react';
import { useState } from 'react';

export default function WaitDisplay(props) {
  
    const [isFetching, setIsFetching] = useState(true);
  
    setTimeout( () => {
      setIsFetching(false)
    }, props.duration);
  
    if(isFetching) {
      
      return (
  
        <div>
        </div>
    
      )
  
    }
  
    return (
  
      <div>
      {props.Component}
      </div>
  
    )
}