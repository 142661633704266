import React, { Component } from "react";
import { auth } from '../services/firebase';
import { db } from '../services/firebase';

import AccountDetailsUpdatePaymentEmail from "./AccountDetailsUpdatePaymentEmail"

export default class AccountDetailsUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      email: "",
      password: "",
      success: false,
      paymentEmail: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
    this.handleSubmitUsername = this.handleSubmitUsername.bind(this);
    this.redirectToPE = this.redirectToPE.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async handleSubmitEmail(event) {
    event.preventDefault();
    const response = auth().currentUser.updateEmail(this.state.email)
      .then(() => {
        this.setState({success: true})
      }).catch((e) => {
        this.setState({error: e.message})
      });
  }

  async handleSubmitUsername(event) {
    event.preventDefault();
    // update username database
          db
          .collection('usernameauth')
          .doc(this.props.user.uid)
          .update({
            username: this.state.username
          })
          .then(() => {
            this.setState({success: true})
          });
  }

  async redirectToPE(event) {
    event.preventDefault();
    this.setState({paymentEmail: true})
  }

  render() {

  if(this.state.success && this.props.field == "username") {

              return (

                <div>
                <section>
                {/*<hr/>*/}
                <div style={{textAlign:"center"}}>
                <h1>Account Details</h1>
                <p>Your {this.props.field} has been updated.</p>
                </div>
                {/*<hr/>*/}
                </section>
                </div>

              )

   } else if (this.state.success && this.props.field == "email" && !this.state.paymentEmail) {

              if(this.props.subbed){

              return (

                <div>
                <section>
                {/*<hr/>*/}
                <div style={{textAlign:"center"}}>
                <h1>Account Details</h1>
                <p>Your {this.props.field} has been updated. </p>
                <p> To also update your payment email, <em onClick={this.redirectToPE}>click here</em></p>
                </div>
                {/*<hr/>*/}
                </section>
                </div>

              )

              } else {

                return (

                  <div>
                  <section>
                  {/*<hr/>*/}
                  <div style={{textAlign:"center"}}>
                  <h1>Account Details</h1>
                  <p>Your {this.props.field} has been updated. </p>
                  </div>
                  {/*<hr/>*/}
                  </section>
                  </div>

                )

              }

   } else if (this.state.paymentEmail) {

              return (

                <div>
                <AccountDetailsUpdatePaymentEmail user={this.props.user}/>
                </div>

              )

   } else if (this.props.field == "email") {

              return (
                <div>
                {/*<hr/>*/}
                <div className="centicons" style={{textAlign:"center"}}>
                <section>
                {/*<hr/>*/}
                  <form
                    autoComplete="off"
                    onSubmit={this.handleSubmitEmail}
                  >
                    <h1>
                    Account Details
                    </h1>
                    <p>
                      Fill in the form below to update your {this.props.field}.
                    </p>
                    <div>
                      <input
                        placeholder="Email"
                        name="email"
                        type="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        className="inputBox"
                      />
                    </div>
                    <div>
                      {this.state.error ? (
                        <p>{this.state.error}</p>
                      ) : null}
                      <button type="submit" className="btn extra auth">Update Email</button>
                    </div>
                  </form>
                {/*<hr/>*/}
                </section>
                </div>
                {/*<hr/>*/}
                </div>
              );

     } else if (this.props.field == "username" && !this.state.success) {

       return (
        <div>
        {/*<hr/>*/}
        <div className="centicons" style={{textAlign:"center"}}>
         <section>
           <form
             autoComplete="off"
             onSubmit={this.handleSubmitUsername}
           >
             <h1>
             Account Details
             </h1>
             <p>
               Fill in the form below to update your {this.props.field}.
             </p>
             <div>
               <input
                 placeholder="Username"
                 name="username"
                 onChange={this.handleChange}
                 value={this.state.username}
                 className="inputBox"
               />
             </div>
             <div>
               {this.state.error ? (
                 <p>{this.state.error}</p>
               ) : null}
               <button type="submit" className="btn extra auth">Update Username</button>
             </div>
           </form>
         </section>
         </div> 
         {/*<hr/>*/}
         </div>
       );

     }

  }

}
