import React from "react"
import {Link} from "react-router-dom";
import { useState, useEffect } from "react"

import UseWindowSize from "./UseWindowSize"

import FadeInSection from "./FadeInSection"
import DateModalCalc from "./DateModalCalc"

function GridVerticaFreeForm(props){

    const size = UseWindowSize()

    const [first, setFirst] = useState([])
    const [others, setOthers] = useState([])

    useEffect(() => {
      if(props.articles[0]){
        setFirst([props.articles[0]])
        const [, ...rest] = props.articles
        setOthers(rest)
      }
    }, [props.articles]);

      return (
        <article className="post">
        {first.length != 0 && first.map(article => (
          <FadeInSection>
          <div id="test">
            <p className="timing">{article.topic.toUpperCase()}</p>
            <hr/>
            <h1 style={{textAlign:"center"}}>{article.title}</h1>
            {article.paras.map(para => (
            <div>
            <p>{para}</p>    
            </div>
            ))} 
            <Link to={article.author}>
            <h1 style={{textAlign:"right"}}>{article.author}</h1>
            </Link>
            <DateModalCalc article={article}/>
            <p className="timingext">{article.geo.toUpperCase()}</p>
          </div>
          </FadeInSection>
        ))}
        {others.length != 0 && others.map(article => (
          <FadeInSection>
          <div>
            <hr/>
            <p className="timing">{article.topic.toUpperCase()}</p>
            <h1 style={{textAlign:"center"}}>{article.title}</h1>
            {article.paras.map(para => (
            <div>
            <p>{para}</p>    
            </div>
            ))} 
            <Link to={article.author}>
            <h1 style={{textAlign:"right"}}>{article.author}</h1>
            </Link>
            <DateModalCalc article={article}/>
            <p className="timingext">{article.geo.toUpperCase()}</p>
          </div>
          </FadeInSection>
        ))}
        </article>
      )

    }

export default GridVerticaFreeForm
