import React from "react"
import { useState, useEffect } from "react"
import { db } from '../services/firebase';
import {withRouter} from 'react-router';

import GridVertica from "./GridVertica"
import ModalExploreEmpty from "./ModalExploreEmpty"

function AdminModalExplore(props){

  const [articles, setArticles] = useState([])
  const [loading, setLoading]= useState(true)

  useEffect(() => {
    const subscriber = db
      .collection('pagesx')
      .where('tags', 'array-contains-any', props.article.tags)
      .get()
      .then(snap => {
        const data = snap.docs.map(doc => doc.data())
        const display = data.filter(function( obj ) {
            return obj.title !== props.article.title;
        });
        setArticles(display)
        setLoading(false)
      })
  }, [props, props.location.pathname]);

  if(loading){
    return(<h1>Retrieving...</h1>)
  }

  if(articles.length !== 0 && !loading){
      return (

        <div>
        <GridVertica articles={articles} />
        </div>

      )
  } else {
      return (
        <div>
        <ModalExploreEmpty tags={['#jesus']} article={props.article}/>
        </div>
      )
  }

}

export default withRouter(AdminModalExplore)
