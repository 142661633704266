import React from "react"
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import {withRouter} from 'react-router';

import SubscriberCurrentGridVertica from "./SubscriberCurrentGridVertica"
import GoldPageEditorsNoteSubscribe from "./GoldPageEditorsNoteSubscribe"

import IconBarSocial from "./IconBarSocial"

function SubscriberCurrentNewsletterInit(props){

    const [articles, setArticles] = useState([{location:"this array is not empty"}])

    useEffect(() => {
      const subscriber = db
        .collection('subscribe')
        .where("location", "==", '/subscribercurrent')
        .get()
        .then(snap => {
          const data = snap.docs.map(doc => doc.data())
          setArticles(data)
        });
    }, []);

          return (
              <div>
              {articles.length != 0 &&
              <div>
              <section>
              <SubscriberCurrentGridVertica articles={articles}/>
              </section>
              <GoldPageEditorsNoteSubscribe newsletter={true} database={"editorsnote"} />
              </div>
              }
              </div>
          )

}

export default withRouter(SubscriberCurrentNewsletterInit)
