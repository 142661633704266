import React from "react"
import {Link} from "react-router-dom";

import UseWindowSize from "./UseWindowSize"

import FadeInSection from "./FadeInSection"
import DateModalCalc from "./DateModalCalc"

function GridVerticaGate(props){

    const size = UseWindowSize()

      return (
        <div>
          <FadeInSection>
          <hr/>
          <p className="timing">{props.article.topic.toUpperCase()}</p>
          <div className="centicons" style={size.width < 1000 ? {paddingTop: "1em"} : {paddingTop: "2em"}}>
          <div className="sidebarx" style={{zIndex: 950}}>
            <Link to={props.article.location}>
            <h1>
            <span className="over">{props.article.title}</span>
            </h1>
            <p style={{fontWeight: 'normal'}}>{props.article.content}</p>
            <h1>
            <span className="over">{props.article.titlex}</span>
            </h1>
            <p style={{fontWeight: 'normal'}}>{props.article.contentx}</p>
            </Link>
          </div>
          <img src={props.article.url}
                    style={{display: "block",
                            width: "70%",
                            height: "70%",
                            objectFit: "cover",
                           zIndex: 900}}
                    alt={props.article.alt}        
                />
          </div>
          <Link to={props.article.author}>
          <h1 style={{textAlign:"right"}}>{props.article.author}</h1>
          </Link>
          <DateModalCalc article={props.article}/>
          <p className="timingext">{props.article.geo.toUpperCase()}</p>
          {/*<hr/>
          <p className="timingext" style={{color:"grey"}}>RACE</p>*/}
          </FadeInSection>
        </div>  
      )

}

export default GridVerticaGate
