// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyBeKr52MtLZCFbl3Lqw_2laKeVl_WbP5kw",
  authDomain: "frbex-1cad6.firebaseapp.com",
  databaseURL: "https://frbex-1cad6.firebaseio.com",
  projectId: "frbex-1cad6",
  storageBucket: "frbex-1cad6.appspot.com",
  messagingSenderId: "813946230123",
  appId: "1:813946230123:web:52875857f489cfeb29a82e"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth;
export const db = firebase.firestore();
export const time = firebase.firestore;
