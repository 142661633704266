import React from "react"
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';

import GridVerticaFreeFormEditors from "./GridVerticaFreeFormEditors"

function GoldPageEditorsNoteSubscribe(props) {

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const subscriber = db
          .collection(props.database)
          .orderBy('timestamp', 'desc')
          .limit(1)
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setArticles(data);
          });
  }, [props.database]);

    return (
        <div>
        <section>
            {articles[0] &&
            <p className="timing">{articles[0].geo.toUpperCase()}</p>
            }
            <hr/>
            <GridVerticaFreeFormEditors newsletter={props.newsletter} articles={articles}/>
            {/*<p className="timingext">NEW YORK, NY</p>*/}
        </section>
        </div>
    );
}

export default GoldPageEditorsNoteSubscribe
