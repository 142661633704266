import React from "react"

function LanguageModalSoon(props){

    return (

      <div>
        <hr/>
        <p className="timingext">ARTICLE SOUL</p>
        <p>{props.soul}</p>
        <hr/>

      {/*<div style={{display:"flex",alignSelf:"center", justifyContent:"center"}}>
      <h1 className="language">Languages coming soon</h1>
      </div>*/}
      </div>



    )


}

export default LanguageModalSoon
