import React from "react"

import SubscribePage from "./SubscribePage"
import SubscriberCurrent from "./SubscriberCurrent"
import WaitDisplay from "./WaitDisplay"

function SubscribeGate(props) {

    if (props.subbed) {
      return (
        <div>
        <SubscriberCurrent />
        </div>
      );
    }

    return (
      <div>
      <WaitDisplay Component={<SubscribePage user={props.user} />} duration={260}/>
      </div>
    );
}

export default SubscribeGate
