import React from "react"
import { useState, useEffect } from "react"
import {Link} from "react-router-dom";
import { db } from '../services/firebase';

import GoldPageEditorsNoteSubscribe from "./GoldPageEditorsNoteSubscribe"

import Newsletter from "./Newsletter"

import UseWindowSize from "./UseWindowSize"
import FadeInSection from "./FadeInSection"

import ExtendedNav from "./ExtendedNav"

import Paradise from "./Paradise"

import IconBarSocial from "./IconBarSocial"

function NewsletterLocked(props){

  const size = UseWindowSize()

    const [articles, setArticles] = useState([])

    useEffect(() => {
      const subscriber = db
        .collection('lockedarticle')
        .orderBy('timestamp', 'desc')
        .limit(1)
        .get()
        .then(snap => {
          const data = snap.docs.map(doc => doc.data())
          setArticles(data);
        });
    }, [props]);

    const [jobs, setJobs] = useState([])

    useEffect(() => {
      const subscriber = db
        .collection('lockeddesc')
        .orderBy('timestamp', 'desc')
        .get()
        .then(snap => {
          const data = snap.docs.map(doc => doc.data())
          setJobs(data);
        });
    }, [props]);

    const [first, setFirst] = useState([])
    const [others, setOthers] = useState([])
    const [final, setFinal] = useState([])

    useEffect(() => {
      if(jobs[0]){
        setFirst([jobs[0]])
        setFinal([jobs[jobs.length-1]])
        const [, ...rest] = jobs
        rest.pop()
        setOthers(rest)
      }
    }, [jobs]);

    function handleClick(){
      if(clicked){
        setClicked(false)
      } else {
        setClicked(true)
      }
    }

    const [soulLink, setsoulLink] = useState("")

    /*

    function handleClick(){
      //window.location.replace('https://airtable.com/shrkXVvmTJvfhaYy5')
      window.location.href = ''https://airtable.com/shrkXVvmTJvfhaYy5'';
    }

    */

    const [clicked, setClicked] = useState(false)

    function handleClick(){
      setClicked(true)
    }

    if(!clicked) {

      return (
      <div id="test">
      <section>
      <Paradise/>
      </section>
      <ExtendedNav/>
      <GoldPageEditorsNoteSubscribe database={"lockednote"}/>
      <section>
      {articles[0] &&
      <hr/>
      }
      {/*<p className="timingext">OPENINGS</p>*/}
      {articles.length != 0 &&
        <div>
        <article className="post">
          <div className="centicons" style={size.width < 1000 ? {paddingTop: "1em"} : {paddingTop: "2em"}}>
          <div className="sidebarx" style={{zIndex: 950}}>
            <div className="over pointy" onClick={handleClick} role="link">
            <h1>
            <span>{articles[0].title}</span>
            </h1>
            <p>{articles[0].content}</p>
            <h1>
            <span className="over">{articles[0].titlex}</span>
            </h1>
            <p>{articles[0].contentx}</p>
            </div>
          </div>
          <img src={articles[0].url}
                    style={{display: "block",
                            width: "70%",
                            height: "70%",
                            objectFit: "cover",
                           zIndex: 900}}
                    alt={articles[0].alt}
                />
          </div>
          <Link to={articles[0].author}>
          <h1 style={{textAlign:"right"}}>{articles[0].author}</h1>
          </Link>
          <p className="timing">{articles[0].topic.toUpperCase()}</p>
          <hr/>
        </article>
          </div>
          }
            {first.length != 0 && first.map(article => (
              <div>
              <h1>{article.title}</h1>
              <div>
              <p>{article.content}</p>
              <p className="timing">{article.topic.toUpperCase()}</p>
              </div>
              </div>
            ))}
            {others.length != 0 && others.map(article => (
              <div>
              <FadeInSection>
              <hr/>
              <h1>{article.title}</h1>
              <div>
              <p>{article.content}</p>
              <p className="timing">{article.topic.toUpperCase()}</p>
              </div>
              </FadeInSection>
              </div>
            ))}
            {final.length != 0 && final.map(article => (
              <div>
              <FadeInSection>
              <hr/>
              <h1>{article.title}</h1>
              <div>
              <p>{article.content}</p>
              <p className="timing">{article.topic.toUpperCase()}</p>
              </div>
              <hr/>
              <IconBarSocial />
              </FadeInSection>
              </div>
            ))}
      </section>
      </div>
    )


  } else {

    return (

      <div>
      <Newsletter subbed={props.subbed} user={props.user}/>
      </div>

    )


  }




}

export default NewsletterLocked
