import React from "react"
import { useState } from "react"

import UseWindowSize from "./UseWindowSize"

import IconDisplayAuth from "./IconDisplayAuth"
import LanguageModalSoon from "./LanguageModalSoon"

function IconBar(props){

const size = UseWindowSize();

      const [clicked, setClicked] = useState(true)

      function handleClick(){
        if(clicked){
          setClicked(false)
        } else {
          setClicked(true)
        }
      }

      return (
        <div>
        <div className="icons">
        <div className="lefticons">
        <IconDisplayAuth
        article={props.article}
        preIcon={<ion-icon name="heart-outline" style={size.width > 1000 ? {fontSize:50} : {fontSize:30, marginRight:5}}></ion-icon>}
        postIcon={<ion-icon name="heart-sharp" style={size.width > 1000 ? {fontSize:50} : {fontSize:30, marginRight:5}}></ion-icon>}
        archive="heartArticles"
        other="savedArticles"
        user={props.user}
        />
        <span className="iconButton" onClick={() => handleClick()}><ion-icon name="earth-outline" style={size.width > 1000 ? {fontSize:50} : {fontSize:30}}></ion-icon></span>
        </div>
                <IconDisplayAuth
                article={props.article}
                preIcon={<ion-icon name="bookmark-outline" style={size.width > 1000 ? {fontSize:50} : {fontSize:30}}></ion-icon>}
                postIcon={<ion-icon name="bookmark-sharp" style={size.width > 1000 ? {fontSize:50} : {fontSize:30}}></ion-icon>}
                archive="savedArticles"
                other="heartArticles"
                user={props.user}
                />
        </div>
        {clicked &&
        <div>
        <LanguageModalSoon soul={props.article.soul}/>
        </div>}
        </div>
      )
    }

export default IconBar
