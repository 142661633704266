import React from "react"
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import {withRouter} from 'react-router';

import SubscriberCurrentGridVertica from "./SubscriberCurrentGridVertica"
import GoldPageEditorsNoteSubscribe from "./GoldPageEditorsNoteSubscribe"

function SubscriberCurrent(props){

    const [articles, setArticles] = useState([{location:"this array is not empty"}])

    useEffect(() => {
      const subscriber = db
        .collection('subscribe')
        .where("location", "==", '/subscribercurrent')
        .get()
        .then(snap => {
          const data = snap.docs.map(doc => doc.data())
          setArticles(data)
        });
    }, []);

      if (articles.length !== 0) {
          return (
              <div>
              <section>
              <SubscriberCurrentGridVertica articles={articles}/>
              </section>
              <GoldPageEditorsNoteSubscribe database={"editorsnote"} />
              </div>
          )
      } else {
            return (
              <div>
              <p>An Error Has Occured</p>
              </div>
            )}
}

export default withRouter(SubscriberCurrent)
