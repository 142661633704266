import React from "react"
import {Link} from "react-router-dom";

import UseWindowSize from "./UseWindowSize"

import NewsletterDateModalCalc from "./NewsletterDateModalCalc"

function NewsletterFreeForm(props){

    const size = UseWindowSize()

      return (
        <article className="post">
          <div>
            <h1 style={{textAlign:"center"}}>{props.article.title2}</h1>
            {props.article.paras.map(para => (
            <div>
            <p>{para}</p>    
            </div>
            ))} 
            <Link to={props.article.author}>
            <h1 style={{textAlign:"right"}}>{props.article.author2}</h1>
            </Link>
            <NewsletterDateModalCalc article={props.article}/>
          </div>
        </article>
      )

    }

export default NewsletterFreeForm
