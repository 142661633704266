/*

import React from 'react';
import { render } from 'react-snapshot';
import App from './App';

render(
    <App />,
  document.getElementById('root')
);


*/

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
