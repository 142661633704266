import React from "react"
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import {withRouter} from 'react-router';

import GridContent from "./GridContent"
import AuthorProfile from "./AuthorProfile"

import ExtendedNav from "./ExtendedNav"
import WaitDisplay from "./WaitDisplay"

function Article(props){

    const [articles, setArticles] = useState([{location:"this array is not empty"}])
    const [author, setAuthor] = useState([{location:"this array is not empty"}])

    useEffect(() => {

          if(props.location.pathname.includes("@")){

                const subscriber = db
                .collection('authors')
                .where("location", "==", props.location.pathname)
                .limit(1)
                .get()
                .then(snap => {
                  const data = snap.docs.map(doc => doc.data())
                  setAuthor(data)
                });

          } else {

              const subscriber = db
                .collection('pagesx')
                .where("location", "==", props.location.pathname)
                .limit(1)
                .get()
                .then(snap => {
                  const data = snap.docs.map(doc => doc.data())
                  setArticles(data)
                });

          }

    }, [props.location.pathname]);

    if(!props.location.pathname.includes("@")){

      if (articles.length !== 0) {
          return (
            <div>
            <ExtendedNav/>
            <WaitDisplay Component={<GridContent articles={articles} user={props.user}/>} duration={200}/>
            </div>
                )
      } else {
          return (
            <div>
            <ExtendedNav/>
            <section>
            <hr/>
            <div style={{textAlign:"center"}}>
            <h1>Request Error</h1>
            <p>The Article Does Not Exist!</p>
            </div>
            <hr/>
            </section>
            </div>
                  )}

  } else {

      if (author.length !== 0) {
        return (
            <div>
            <ExtendedNav/>
            <WaitDisplay Component={<AuthorProfile author={author[0]} user={props.user}/>} duration={500}/>
            </div>
        )

      } else {

          return (
            <div>
            <ExtendedNav/>
            <section>
            <hr/>
            <div style={{textAlign:"center"}}>
            <h1>Request Error</h1>
            <p>The Author Does Not Exist!</p>
            </div>
            <hr/>
            </section>
            </div>
          )}



    }
}

export default withRouter(Article)
