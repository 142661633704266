import React from "react"
import { useState } from "react"

function AccountDetailsUnsubscribe(props){

  const [unsubscribe, setUnsubscribe] = useState(false)

  const handleClick = async (event) => {
    const cancelledResponse = await cancelSubscription(props.user);
    setUnsubscribe(true)
  }

  async function cancelSubscription (user) {

    const firestoreResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/getFirestoreCustomer', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              userId: user.uid,
              })
      })

      const firestoreData = await firestoreResponse.json()
      var firestoreBody = JSON.parse(firestoreData.body);
      var customerId = firestoreBody.customerId;

    const stripeResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/getSubscriptionId', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              customerId: customerId,
              })
      })

      const stripeData = await stripeResponse.json()
      var stripeBody = JSON.parse(stripeData.body);
      var subscriptionId = stripeBody.subscriptionId;

    const stripeCancelResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/cancelSubscription', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              subscriptionId: subscriptionId,
              })
      })

      const stripeCancelData = await stripeCancelResponse.json()
      var stripeCancelBody = JSON.parse(stripeCancelData.body);
      var cancelledResponse = stripeCancelBody.response;
      return cancelledResponse

  }

  if(!unsubscribe){
          return (

                <div>
                <section>
                <hr/>
                <div style={{textAlign:"center"}}>
                <h1>Change Subscription</h1>
                <p>Clicking below will unsubscribe your account. This action cannot be undone.</p>
                <button className="btn extra auth" onClick={handleClick}>Unsubscribe</button>
                </div>
                <hr/>
                </section>
                </div>
          )

  } else {

          return (

            <div>
            <section>
            <hr/>
            <div style={{textAlign:"center"}}>
            <h1>Account Credentials</h1>
            <h1>You have sucessfully been unsubscribed.</h1>
            </div>
            <hr/>
            </section>
            </div>
            
          )

  }

}

export default AccountDetailsUnsubscribe
