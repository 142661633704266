import React from "react"
import { useState, useEffect } from "react"

import { db } from '../services/firebase';

function Paradise(props){

    /*

    const [destination, setDestination] = useState("")

    useEffect(() => {

        setDestination(props.article.location)

    }, [props]);

    */

    const [paradise, setParadise] = useState([]);
    const [destination, setDestination] = useState("");

    useEffect(() => {
      if(!props.art){
        const subscriber = db
          .collection("paradisesoul")
          .orderBy('timestamp', 'desc')
          .limit(1)
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setParadise(data);
            setDestination("https://www.kujeera.com")
          });
      } else {
        const subscriber = db
          .collection("artistinres")
          .orderBy('timestamp', 'desc')
          .limit(1)
          .get()
          .then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setParadise(data);
            setDestination("https://www.kujeera.com/art")
          });
      }
    }, [props.database]);

      return (
        <div className="paradiseHolder">
        <a href={destination}>
        {paradise[0] &&
        <p className="timingext paradiseLine">{paradise[0].title.toUpperCase()}</p>
        }
        </a>
        </div>
      )

}

export default Paradise
