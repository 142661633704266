import React from 'react'
import { time } from '../services/firebase';
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';

function IconDisplay(props){

    const [data, setData] = useState([]);

    useEffect(() => {

          const subscriber = db
                .collection('usercontent')
                .doc(props.user.uid)
                .collection(props.archive).onSnapshot(snap => {
          const data = snap.docs.map(doc => doc.data())
          setData(data)
       });

    return () => subscriber();
  }, [props.archive, props.other]);

     const [other, setOther] = useState([]);

     useEffect(() => {

           const subscriber = db
                 .collection('usercontent')
                 .doc(props.user.uid)
                 .collection(props.other).onSnapshot(snap => {
           const data = snap.docs.map(doc => doc.data())
           setOther(data)
        });
     return () => subscriber();
   }, [props.archive, props.other]);

    var bool = data.some(item => item.title === props.article.title)

    var boolx = other.some(item => item.title === props.article.title)

    function deleteArticle() {

          db
          .collection('usercontent')
          .doc(props.user.uid)
          .collection(props.archive)
          .doc(props.article.title)
          .delete()
          .then(() => {
          });

    }

    function deleteArticlex() {

          db
          .collection('usercontent')
          .doc(props.user.uid)
          .collection(props.archive)
          .doc(props.article.title)
          .delete()
          .then(() => {
          });

          db
          .collection('usercontent')
          .doc(props.user.uid)
          .collection("allSavedArticles")
          .doc(props.article.title)
          .delete()
          .then(() => {
          });

    }

    function saveArticle() {

          db
          .collection('usercontent')
          .doc(props.user.uid)
          .collection(props.archive)
          .doc(props.article.title)
          .set({
                title: props.article.title,
                content: props.article.content,
                titlex: props.article.titlex,
                contentx: props.article.contentx,
                url: props.article.url,
                location: props.article.location,
                author: props.article.author,
                geo: props.article.geo,
                topic: props.article.topic,
                timestamp: time.FieldValue.serverTimestamp()
              })
          .then(() => {
          });

          db
          .collection('usercontent')
          .doc(props.user.uid)
          .collection("allSavedArticles")
          .doc(props.article.title)
          .set({
                title: props.article.title,
                content: props.article.content,
                titlex: props.article.titlex,
                contentx: props.article.contentx,
                url: props.article.url,
                location: props.article.location,
                author: props.article.author,
                geo: props.article.geo,
                topic: props.article.topic,
                timestamp: time.FieldValue.serverTimestamp()
              })
          .then(() => {
          });
    }

    if(bool) {
        if(boolx){
                return (
                  <div>
                  <span onClick={(boolx) => deleteArticle(boolx)} className="iconButton">
                  {props.postIcon}
                  </span>
                  </div>
                )
        } else {
                return (
                  <div>
                  <span onClick={(boolx) => deleteArticlex(boolx)} className="iconButton">
                  {props.postIcon}
                  </span>
                  </div>
                )
        }
    } else {
      return (
        <div>
        <span onClick={saveArticle} className="iconButton">
        {props.preIcon}
        </span>
        </div>
      )
    }
}

export default IconDisplay
