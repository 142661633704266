import React from "react"
import {useState, useEffect} from "react"
import {withRouter} from 'react-router';
import { Link } from "react-router-dom";

import ModalHeadline from "./ModalHeadline"
import ModalExplore from "./ModalExplore"

import UseWindowSize from "./UseWindowSize"

function IconModal(props){

  const size = UseWindowSize();

  // swap

  const [headline, setheadline] = useState(true);
  const [explore, setexplore] = useState(false);

  const [username, setUsername] = useState("");

  function initial(){
    setheadline(true)
    setexplore(false)
  }

  useEffect(() => {
    initial()
  }, [props.location.pathname]);

  useEffect(() => {
    setUsername("/" + props.article.author)
  }, [props.article.author]);

  function chooseheadline() {

    setheadline(true)
    setexplore(false)

  }

  function chooseauthor() {

    setheadline(false)
    setexplore(false)

  }

  function chooseexplore() {

    setheadline(false)
    setexplore(true)

  }


  // end swap

    if(headline){

      return (

        <div>
        <div>
        <ul>
        <li><span onClick={chooseheadline} style={{textDecoration:"underline", fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#headline</em></p></span></li>
        <Link to={username}><li><span onClick={chooseauthor}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>{props.article.author}</em></p></span></li></Link>
        <li><span style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}>|</p></span></li>
        <li><span  onClick={chooseexplore} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>explore</em></p></span></li>
        </ul>
        </div>
        <ModalHeadline article={props.article}/>
        <div className="righticons">
            <p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}} style={{fontFamily: "Sansita One",fontSize: '10'}}>
            photocr: {props.article.photocr}
            </p>
        </div>
        </div>
      )

    } else {

      return (

        <div>
        <div>
        <ul>
        <li><span onClick={chooseheadline} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#headline</em></p></span></li>
        <Link to={username}><li><span onClick={chooseauthor}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>{props.article.author}</em></p></span></li></Link>
        <li><span style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}>|</p></span></li>
        <li><span  onClick={chooseexplore} style={{textDecoration:"underline", fontWeight:"bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>explore</em></p></span></li>
        </ul>
        </div>
        <ModalExplore article={props.article}/>
        </div>

      )

    }

}

export default withRouter(IconModal)
