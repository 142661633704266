import React from "react"
import {Link} from "react-router-dom";
import {withRouter} from 'react-router';

import IconBar from "./IconBar"
import IconModal from "./IconModal"
import FadeInSection from "./FadeInSection"
import DateModalCalc from "./DateModalCalc"
import IconBarSocial from "./IconBarSocial"

function GridContent(props){

      return (
        <div>
        <section>
            <article className="post">
            {props.articles.map(article => (
              <div id="test">
              {article.timestamp && <DateModalCalc article={article}/>}
              <hr />
              {/* article.timestamp && <DateModalCalc article={article}/>  */}
              <Link to={article.location}><h1>{article.title}</h1></Link>
              <p>{article.content}</p>
              <div>
              <FadeInSection>  
                <img src={article.url}
                        style={{display: "block",
                                width: "100%",
                                height: "100%",
                                objectFit: "contain"}}
                        alt={article.alt}         
                    />
              <IconBar article={article} user={props.user}/>
              </FadeInSection>
              {/*</FadeInSection>*/}
              <FadeInSection>
              <IconModal article={article}/>
              {article.geo &&
                  <p className="timingext">{article.geo.toUpperCase()}</p>
              }    
              </FadeInSection>
              </div>
              </div>
            ))}
            </article>
            {props.articles[0].geo &&
              <IconBarSocial />    
            }
        </section>
        </div>
      )
    }

export default withRouter(GridContent)
