import React from 'react';
import { useState, useEffect } from 'react';
import About from './About'
import Admin from './Admin'

function AdminGate(props){

      async function checkAdmin (person) {

          const firestoreResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/isAdmin', {
              method: 'POST',
              // Adding the order data to payload
              body: JSON.stringify({
                    userId: person.uid,
                    })
            })

            const firestoreData = await firestoreResponse.json()
            var firestoreBody = JSON.parse(firestoreData.body);
            var jsonResponse = firestoreBody.response;
            return jsonResponse
      }

      const [isAdmin, setIsAdmin] = useState(false)
      const [initializing, setInitializing] = useState(true)

      useEffect(() => {
        async function scheckAdmin() {
          const response = await checkAdmin(props.user);
          if(response.value){
            setIsAdmin(true)
          } else {
            setIsAdmin(false)
          }
        }

        if(props.user){
        scheckAdmin()
        } else {
        setIsAdmin(false)
        }
        if (initializing) setInitializing(false)
      }, [props.user]);

      if (initializing) {

        return null;

      }

      if (!isAdmin) {

        return (
          <About />
        )

      }

      return (
         <Admin />
      )

}

export default AdminGate
