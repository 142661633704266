import React from "react"
import {useState, useEffect} from "react"
import {withRouter} from 'react-router';

import AdminModalHeadline from "./AdminModalHeadline"
import AdminModalBegin from "./AdminModalBegin"
import AdminModalExplore from "./AdminModalExplore"

function AdminIconModal(props){

  // swap

  const [geofeeds, setgeofeeds] = useState(false);
  const [thewatch, setthewatch] = useState(true);
  const [merch, setmerch] = useState(false);

  function initial(){
    setthewatch(false)
    setthewatch(true)
    setmerch(false)
  }

  useEffect(() => {
    initial()
  }, [props.location.pathname]);

  function choosegeofeeds() {

    setgeofeeds(true)
    setthewatch(false)
    setmerch(false)

  }

  function choosethewatch() {

    setgeofeeds(false)
    setthewatch(true)
    setmerch(false)

  }

  function choosemerch() {

    setgeofeeds(false)
    setthewatch(false)
    setmerch(true)

  }


  // end swap

    if(geofeeds){

      return (

        <div>
        <div className="centicons">
        <div
          className="profile"
          onClick={choosegeofeeds}
          style={{backgroundColor:"grey"}}>
        <h1>headline</h1>
        </div>
        <div
          className="profile"
          onClick={choosethewatch}>
        <h1>newbeginnings</h1>
        </div>
        <div
          className="profile"
          onClick={choosemerch}>
        <h1>explore</h1>
        </div>
        </div>
        <AdminModalHeadline article={props.article}/>
        <div className="righticons">
            <p style={{fontFamily: "Sansita One",fontSize: '10', color: 'white'}}>
            {props.article.photocr}
            </p>
        </div>
        </div>

      )

    } else if (thewatch) {

      return (

        <div>
        <div className="centicons">
        <div
          className="profile"
          onClick={choosegeofeeds}>
        <h1>headline</h1>
        </div>
        <div
          className="profile"
          onClick={choosethewatch}
          style={{backgroundColor:"grey"}}>
        <h1>newbeginnings</h1>
        </div>
        <div
          className="profile"
          onClick={choosemerch}>
        <h1>explore</h1>
        </div>
        </div>
        <AdminModalBegin article={props.article}/>
        <div className="righticons">
            <p style={{fontFamily: "Sansita One",fontSize: '10', color: 'white'}}>
            {props.article.photocr}
            </p>
        </div>
        </div>

      )

    } else {

      return (

        <div>
        <div className="centicons">
        <div
          className="profile"
          onClick={choosegeofeeds}>
        <h1>headline</h1>
        </div>
        <div
          className="profile"
          onClick={choosethewatch}>
        <h1>newbeginnings</h1>
        </div>
        <div
          className="profile"
          onClick={choosemerch}
          style={{backgroundColor:"grey"}}>
        <h1>explore</h1>
        </div>
        </div>
        <AdminModalExplore article={props.article}/>
        </div>

      )

    }

}

export default withRouter(AdminIconModal)
