import React, { Component } from "react";
import { signup } from "../helpers/auth";
import { auth } from '../services/firebase';
import { db } from '../services/firebase';
import { Link } from "react-router-dom";

import ExtendedNav from "./ExtendedNav"

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      email: "",
      password: "",

      fetched: ["cactus"],
      finalStage: false,
      init: true,

      username: "",

      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitSignup = this.handleSubmitSignup.bind(this);
    this.handleSubmitSignupFinal = this.handleSubmitSignupFinal.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.createAccountAll = this.createAccountAll.bind(this);

  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  fetchUser() {
    auth().fetchSignInMethodsForEmail(this.state.email)
      .then((data) => {
        if(data) {
        this.setState({fetched: data})
        }
        if(data.length != 0){
        this.setState({error: "The email is already in use."})
        }
        this.setState({init: false})
      })
      .catch(e => {
        this.setState({init: true})
        this.setState({fetched: []})
        this.setState({error: e.message})
      })
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: "" });
    this.fetchUser()
  }

  async componentDidMount() {

    auth().onAuthStateChanged(async (user) => {

      if (user && this.state.fetched.length == 0) {

              db
              .collection('usernameauth')
              .doc(user.uid)
              .set({
                username: this.state.username
              })
              .then(() => {
              });

              const response = await this.createAccountAll(user, this.state.username)

      } else {
      }
    })

  }

  async createAccountAll (user, username) {

    const stripeResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/createStripeCustomer', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              email: user.email,
              })
      })

    const stripeData = await stripeResponse.json()
    var stripeBody = JSON.parse(stripeData.body);
    var customerId = stripeBody.customerId;

    const firestoreResponseStripe = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/createFirestoreCustomer', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              customerId: customerId,
              userId: user.uid,
              })
      })

      const firestoreDataStripe = await firestoreResponseStripe.json()
      var firestoreBodyStripe = JSON.parse(firestoreDataStripe.body);
      var jsonResponseStripe = firestoreBodyStripe;

      const firestoreResponseUsDt = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/createUserData', {
          method: 'POST',
          // Adding the order data to payload
          body: JSON.stringify({
                customerId: customerId,
                userId: user.uid,
                email: user.email,
                username: username
                })
        })

        const firestoreDataUsDt = await firestoreResponseUsDt.json()
        var firestoreBodyUsDt = JSON.parse(firestoreDataUsDt.body);
        var jsonResponseUsDt = firestoreBodyUsDt;
        return [customerId, jsonResponseStripe, jsonResponseUsDt]

  }



  async handleSubmitSignup(event) {

    event.preventDefault();

    if(this.state.password.length < 6){
      this.setState({error: "Password must be at least 6 characters"})
    } else {
      this.setState({finalStage: true})
    }

  }

  async handleSubmitSignupFinal(event) {

    event.preventDefault();

    if(this.state.username == ""){

      this.setState({error: "Username undefined."})

    } else {

      signup(this.state.email, this.state.password)
      .then(() => {
        this.setState({loading: true})
      })
      .catch(e => {
        if (e.code === 'auth/email-already-in-use') {
        }
        if (e.code === 'auth/invalid-email') {
        }
        this.setState({ error: e.message });
      });

    }

    // cloud function create user

  }

  resetAll(){
    this.setState({
      error: null,
      email: "",
      password: "",

      fetched: [],
      init: true,

      username: "",

      finalStage: false,
    })
  }

  render() {

    if(this.state.loading) {

      return (
        <div>
        <h1>Creating Account...</h1>
        </div>
      )

    } else if(this.state.init || this.state.fetched.length != 0){
        return (
                <div style={{textAlign:"center"}}>
                <ExtendedNav/>
                <section>
                {/*<hr/>*/}
                  <form
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                  >
                    <h1>
                      Signup
                    </h1>
                    <div>
                      <input
                        placeholder="Email"
                        name="email"
                        type="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        className="inputBox"
                      />
                    </div>
                    <div>
                      {this.state.error ? (
                        <p>{this.state.error}</p>
                      ) : null}
                      <button type="submit" className="btn extra auth">Continue</button>
                    </div>
                  </form>
                  <p>
                  Already have an account? <Link to="/login">Login</Link>
                  </p>
                {/*<hr/>*/}
                </section>
                </div>
              )

    } else if (this.state.fetched.length == 0 && !this.state.finalStage) {

      return (
              <div style={{textAlign:"center"}}>
              <ExtendedNav/>
              <section>
                <form onSubmit={this.handleSubmitSignup}>
                  <h1>
                    Signup
                  </h1>
                  <div onClick={this.resetAll}>
                    <input placeholder="Email" name="email" type="email" value={this.state.email} className="inputBox"></input>
                  </div>
                  <div>
                    <input placeholder="Password" name="password" onChange={this.handleChange} value={this.state.password} type="password" className="inputBox"></input>
                  </div>
                  <div>
                    {this.state.error ? <p>{this.state.error}</p> : null}
                    <button type="submit" className="btn extra auth">Continue</button>
                  </div>
                </form>
                <p>
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              {/*<hr/>*/}
              </section>
              </div>

        )

    } else {

        return (
                <div style={{textAlign:"center"}}>
                <ExtendedNav/>
                <section>
                {/*<hr/>*/}
                  <form onSubmit={this.handleSubmitSignupFinal}>
                    <h1>
                      Signup
                    </h1>
                    <div onClick={this.resetAll}>
                      <input placeholder="Email" name="email" type="email" value={this.state.email} className="inputBox"></input>
                    </div>
                    <div>
                      <input placeholder="Password" name="password" onChange={this.handleChange} value={this.state.password} type="password" className="inputBox"></input>
                    </div>
                    <div>
                      <input placeholder="Name" name="username" onChange={this.handleChange} value={this.state.username} className="inputBox"></input>
                    </div>
                    <div>
                      {this.state.error ? <p>{this.state.error}</p> : null}
                      <button type="submit" className="btn extra auth">Create Account</button>
                    </div>
                  </form>
                  <p>
                  Already have an account? <Link to="/login">Login</Link>
                  </p>
                {/*<hr/>*/}
                </section>
                </div>

          )


    }
  }
}
