import React from "react"
import { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import {withRouter} from 'react-router';

import AboutPageGridVertica from "./AboutPageGridVertica"
import GoldPageEditorsNoteSubscribe from "./GoldPageEditorsNoteSubscribe"

import ExtendedNav from "./ExtendedNav"


function About(props){

    const [articles, setArticles] = useState([{location:"this array is not empty"}])

    useEffect(() => {
      const subscriber = db
        .collection('about')
        .where("location", "==", '/about')
        .get()
        .then(snap => {
          const data = snap.docs.map(doc => doc.data())
          setArticles(data)
        });
    }, []);

          return (
              <div id="test">
              {articles.length != 0 &&
              <div>
              <ExtendedNav/>
              <section>
              <AboutPageGridVertica articles={articles} user={props.user}/>
              </section>
              <GoldPageEditorsNoteSubscribe database={"editorsnote"}/>
              <section>
              {/*<IconBarSocial/>*/}
              </section>
              </div>
              }
              </div>
          )
}

export default withRouter(About)
