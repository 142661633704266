import React from "react"

function AdminGridVertica(props){

      return (
        <article className="post">
        {props.articles.map(article => (
          <div className="centicons" style={{paddingTop: "2em"}}>
          <div className="sidebarx" style={{zIndex: 950}}>
          <h1>
          <span className="over">{article.title}</span>
          </h1>
            <div>{article.content}</div>
            <h1>
            <span className="over">{article.titlex}</span>
            </h1>
            <div>{article.contentx}</div>
          </div>
          <img src={article.url}
                    style={{display: "block",
                            width: "70%",
                            height: "70%",
                            objectFit: "cover",
                           zIndex: 900}}
                    alt={article.alt}    
                />
          </div>

        ))}
        </article>
      )
    }

export default AdminGridVertica
