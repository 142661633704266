import React from 'react'
import { Link } from 'react-router-dom';

import IconDisplay from './IconDisplay'

function IconDisplayAuth(props) {

    if (!props.user) {

          return (
            <div>
            <Link to="/login">
            <span className="iconButton">
            {props.preIcon}
            </span>
            </Link>
            </div>
          );

    } else {
          return (
          <div>
          <IconDisplay
          user={props.user}
          article={props.article}
          preIcon={props.preIcon}
          postIcon={props.postIcon}
          archive={props.archive}
          other={props.other}/>
          </div>
        );

    }
}

export default IconDisplayAuth
