import React from "react"
import { useState } from "react"

import UseWindowSize from "./UseWindowSize"

import GoldPageFrontGate2 from "./GoldPageFrontGate2"
import GeoFeedsGate from "./GeoFeedsGate"
import UseInfiniteScrollFetchFree from "./UseInfiniteScrollFetchFree"
import GoldPageEditorsNote from "./GoldPageEditorsNote"


function GoldPage(props){

  const size = UseWindowSize();

      const [clicked, setClicked] = useState(false)

  // swap

  const [race, setrace] = useState(false);
  const [culture, setculture] = useState(false);
  const [society, setsociety] = useState(false);
  const [main, setmain] = useState(true);

  function chooserace() {

    setrace(true)
    setculture(false)
    setsociety(false)
    setmain(false)

  }

  function chooseculture() {

    setrace(false)
    setculture(true)
    setsociety(false)
    setmain(false)

  }

  function choosesociety() {

    setrace(false)
    setculture(false)
    setsociety(true)
    setmain(false)

  }

  function choosemain() {

    setrace(false)
    setculture(false)
    setsociety(false)
    setmain(true)

  }

  // end swap

    if(race){

      return (

        <div>
        <section id="myDIV" style={{display:"none"}}>
        <ul>
        <li><span onClick={choosemain} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>cover</em></p></span></li>
        <li><span onClick={choosemain} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}>|</p></span></li>
        <li><span onClick={chooserace} style={{textDecoration:"underline", fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#bre</em></p></span></li>
        <li><span onClick={chooseculture} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#on</em></p></span></li>
        <li><span onClick={choosesociety} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#na</em></p></span></li>
        </ul>
        </section>
        <UseInfiniteScrollFetchFree database={"freeform"}/>
        </div>

      )

    } else if (culture) {

      return (

        <div>
        <section id="myDIV" style={{display:"none"}}>
        <ul>
        <li><span onClick={choosemain} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>cover</em></p></span></li>
        <li><span onClick={choosemain} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}>|</p></span></li>
        <li><span onClick={chooserace} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#bre</em></p></span></li>
        <li><span onClick={chooseculture}  style={{textDecoration:"underline", fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#on</em></p></span></li>
        <li><span onClick={choosesociety} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#na</em></p></span></li>
        </ul>
        </section>
        <div id="test">
        <GoldPageEditorsNote database={"editorsnote"} />
        </div>
        </div>

      )

    } else if (society) {

      return (

        <div>
        <section id="myDIV" style={{display:"none"}}>
        <ul>
        <li><span onClick={choosemain} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>cover</em></p></span></li>
        <li><span onClick={choosemain} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}>|</p></span></li>
        <li><span onClick={chooserace} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#bre</em></p></span></li>
        <li><span onClick={chooseculture} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#on</em></p></span></li>
        <li><span onClick={choosesociety} style={{textDecoration:"underline", fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#na</em></p></span></li>
        </ul>
        </section>
        <GeoFeedsGate subbed={props.subbed} database={"culture"}/>
        </div>

      )

    } else {

      return (

        <div>
        <section id="myDIV" style={{display:"none"}}>
        <ul>
        <li><span onClick={choosemain}  style={{textDecoration:"underline", fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>cover</em></p></span></li>
        <li><span onClick={choosemain} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}>|</p></span></li>
        <li><span onClick={chooserace} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#bre</em></p></span></li>
        <li><span onClick={chooseculture} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#on</em></p></span></li>
        <li><span onClick={choosesociety} style={{fontWeight: "bold"}}><p style={size.width > 1000 ? {fontSize:"1.5em"} : {fontSize:"1em"}}><em>#na</em></p></span></li>
        </ul>
        </section>
        <GoldPageFrontGate2 subbed={props.subbed} database={"coversx"}/>
        {/*<GoldPageEditorsNoteSubscribe database={"editorsnote"} />*/}
        {/*<section>
        <IconBarSocial/>
        </section>*/}
        </div>

      )

    }

}

export default GoldPage
