import React from "react"

function ModalHeadline(props){

  return (

    <div>
    <h1>{props.article.titlex}</h1>
    <p>{props.article.p1}</p>
    <h1>{props.article.titley}</h1>
    <p>{props.article.p2}</p>
    <p>{props.article.p3}</p>
    </div>

  )

}

export default ModalHeadline
