import React from "react"

function AdminModalBegin(props){

  return(

    <div>
    <h1>The BackStory</h1>
    <p>{props.article.history}</p>
    <h1>The People</h1>
    <p>{props.article.person1}</p>
    <p>{props.article.person2}</p>
    <p>{props.article.person3}</p>
    <p>{props.article.person4}</p>
    <h1>The Places</h1>
    <p>{props.article.base}</p>
    </div>

  )

}

export default AdminModalBegin
