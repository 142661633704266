import React from "react"

import { useState } from "react"

import UseWindowSize from "./UseWindowSize"

import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_b3PdegDykejp20fvYA7nURRY00ySm05K9G');

function SubscribeGridVertica(props){

    const size = UseWindowSize()

    const [clicked, setClicked] = useState(null)

    const handleClick = async (event) => {
      setClicked(true)
      // Call your backend to create the Checkout session.
      const { sessionId } = await checkoutCustomer(props.user);
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
    };

    async function checkoutCustomer (user) {

      const firestoreResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/getFirestoreCustomer', {
          method: 'POST',
          // Adding the order data to payload
          body: JSON.stringify({
                userId: user.uid,
                })
        })

        const firestoreData = await firestoreResponse.json()
        var firestoreBody = JSON.parse(firestoreData.body);
        var customerId = firestoreBody.customerId;

      const response = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/checkoutCustomer', {
          method: 'POST',
          // Adding the order data to payload
          body: JSON.stringify({
                customerId: customerId,
                path: window.location.href,
                })
        })

      const data = await response.json()
      var body = JSON.parse(data.body);
      var sessionId = body.sessionId;
      return {sessionId: sessionId}

    }

    if(!clicked){

            return (
              <article className="post">
              {props.articles.map(article => (
                <div className="centicons" style={size.width < 1000 ? {paddingTop: "1em"} : {paddingTop: "2em"}}>
                <div className="sidebarx" style={{zIndex: 950}}>
                  <div className="over pointy" onClick={handleClick} role="link">
                  <h1>
                  <span>{article.title}</span>
                  </h1>
                  <p>{article.content}</p>
                  <h1>
                  <span className="over">{article.titlex}</span>
                  </h1>
                  <p>{article.contentx}</p>
                  </div>
                </div>

                <img src={article.url}
                          style={{display: "block",
                                  width: "70%",
                                  height: "70%",
                                  objectFit: "cover",
                                 zIndex: 900}}
                          alt={article.alt}
                      />
                </div>

              ))}
              </article>
            )

    } else {

      return (

        <div>
        <h1>Retrieving...</h1>
        </div>

      )
    }

}

export default SubscribeGridVertica
