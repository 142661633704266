import React from "react"
import { useState } from "react"
import { auth } from '../services/firebase';
import { db } from '../services/firebase';

function AccountDetailsDelete(props){

  const [elim, setElim] = useState(false)
  const [clicked, setClicked] = useState(false)

  const handleClick = async (event) => {
    setClicked(true)
    deleteUsername()
    deleteUserContent()
    const totalDeleteUserResponse = await totalDeleteUser(props.user)
    const deleteStripeCustomerResponse = await deleteStripeCustomer(props.user)
    const deleteFirestoreCustomerResponse = await deleteFirestoreCustomer(props.user)
    deleteAuth()
    setElim(true)
  }

  async function deleteUsername() {
    // delete username database
          db
          .collection('usernameauth')
          .doc(props.user.uid)
          .delete()
          .then(() => {
          });
  }

  async function deleteUserContent() {
    // delete username database

          db
          .collection('usercontent')
          .doc(props.user.uid)
          .delete()
          .then(() => {
          });

  }

  async function totalDeleteUser(user) {

    const pullResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/pullUserData', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              userId: user.uid,
              })
      })

    const pullData = await pullResponse.json()
    var pullBody = JSON.parse(pullData.body);
    var pullUser = pullBody.response;

    const removeResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/removeUserData', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              userId: pullUser.userId,
              customerId: pullUser.customerId,
              originalEmail: pullUser.email,
              finalEmail: user.email,
              //created: pullUser.created,
              username: pullUser.username,
              })
      })

    const removeData = await removeResponse.json()
    var removeBody = JSON.parse(removeData.body);
    var removePush = removeBody.response;

    return [removePush]

  }

  async function deleteStripeCustomer (user) {

    const firestoreResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/getFirestoreCustomer', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              userId: user.uid,
              })
      })

      const firestoreData = await firestoreResponse.json()
      var firestoreBody = JSON.parse(firestoreData.body);
      var customerId = firestoreBody.customerId;

    const stripeResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/deleteStripeCustomer', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              customerId: customerId,
              })
      })

    const stripeData = await stripeResponse.json()
    var stripeBody = JSON.parse(stripeData.body);
    var deleteResponse = stripeBody.response;
    return deleteResponse

  }

  async function deleteFirestoreCustomer (user) {

    const firestoreResponse = await fetch('https://us-central1-frbex-1cad6.cloudfunctions.net/deleteFirestoreCustomer', {
        method: 'POST',
        // Adding the order data to payload
        body: JSON.stringify({
              userId: user.uid,
              })
      })

      const firestoreData = await firestoreResponse.json()
      var firestoreBody = JSON.parse(firestoreData.body);
      var deletedResponse = firestoreBody.response;
      return deletedResponse

  }

  async function deleteAuth() {

      const response = auth().currentUser.delete()
          .then(() => {
          }).catch((e) => {
          });

  }

  if(!elim) {
          return (

                <div>
                <section>
                <hr/>
                <div style={{textAlign:"center"}}>
                <h1>Account Deletion</h1>
                <p>Clicking below will delete your account. This action cannot be undone.</p>
                <button className="btn extra auth" onClick={handleClick}>Delete Account</button>
                {clicked &&
                <h1>Deleting...</h1>
                }
                </div>
                <hr/>
                </section>
                </div>

          )

  } else {

          return (
            <div>
            <section>
            <hr/>
            <div style={{textAlign:"center"}}>
            <h1>Account Credentials</h1>
            <p>Your account has been deleted.</p>
            </div>
            <hr/>
            </section>
            </div>
          )

  }

}

export default AccountDetailsDelete
