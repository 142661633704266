import React from 'react'
import {Link} from "react-router-dom";

import UseWindowSize from "./UseWindowSize"
import DateModal from "./DateModal"
import DateModalCalc from "./DateModalCalc"

import FadeInSection from "./FadeInSection"

function GridVerticaGateInit(props){

    const size = UseWindowSize()

      return (
        <div id="test">
          <FadeInSection>
          <DateModal/>       
          <hr/>
          <div className="centicons" style={size.width < 1000 ? {paddingTop: "1em"} : {paddingTop: "2em"}}>
          <div className="sidebarx" style={{zIndex: 950}}>
            <Link to={props.article.location}>
            <h1>
            <span className="over">{props.article.title}</span>
            </h1>
            <p style={{fontWeight: 'normal'}}>{props.article.content}</p>
            <h1>
            <span className="over">{props.article.titlex}</span>
            </h1>
            <p style={{fontWeight: 'normal'}}>{props.article.contentx}</p>
            </Link>
          </div>
          <img src={props.article.url}
                    style={{display: "block",
                            width: "70%",
                            height: "70%",
                            objectFit: "cover",
                           zIndex: 900}}
                    alt={props.article.alt}        
                />
          </div>
          <Link to={props.article.author}>
          <h1 style={{textAlign:"right"}}>{props.article.author}</h1>
          </Link>
          {/*
            <p style={{color:"grey", fontSize:".6em"}}>
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            I never thought race would matter until it did.
            </p>
          */} 
          <DateModalCalc article={props.article}/>
          <p className="timingext">{props.article.geo.toUpperCase()}</p>
          {/*<p style={{color:"grey", fontSize:".8em"}}>26 SECONDS AGO</p>*/}
          </FadeInSection>
        </div>  
      )

}

export default GridVerticaGateInit
