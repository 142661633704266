import React from "react"

import UseWindowSize from "./UseWindowSize"

function AboutPageGridVertica(props){

    const size = UseWindowSize()

            return (
              <article className="post">
              {props.articles.map(article => (
                <div className="centicons" style={size.width < 1000 ? {paddingTop: "1em"} : {paddingTop: "2em"}}>
                <div className="sidebarx" style={{zIndex: 950}}>
                  <h1>
                  <span className="over">{article.title}</span>
                  </h1>
                  <p>{article.content}</p>
                  <h1>
                  <span className="over">{article.titlex}</span>
                  </h1>
                  <p>{article.contentx}</p>
                </div>
                <img src={article.url}
                          style={{display: "block",
                                  width: "70%",
                                  height: "70%",
                                  objectFit: "cover",
                                 zIndex: 900}}
                           alt={article.alt}      
                      />
                </div>

              ))}
              </article>
            )

}

export default AboutPageGridVertica
